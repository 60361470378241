import resolveConfig from 'tailwindcss/resolveConfig'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { head, isNil, map, multiply, sum, props, reject, anyPass, isEmpty } from 'ramda'
import moment from 'moment'

const loanInterestConfig = {
  7: 5,
  14: 10,
  30: 20,
  60: 40
}

const chargesConfig = {
  service_charge: 0,
  excise_duty: 0
}

export const tailwindConfig = () => {
  return resolveConfig('./tailwind.config.js')
}

export const hexToRGB = (h) => {
  let r = 0
  let g = 0
  let b = 0
  if (h.length === 4) {
    r = `0x${h[1]}${h[1]}`
    g = `0x${h[2]}${h[2]}`
    b = `0x${h[3]}${h[3]}`
  } else if (h.length === 7) {
    r = `0x${h[1]}${h[2]}`
    g = `0x${h[3]}${h[4]}`
    b = `0x${h[5]}${h[6]}`
  }
  return `${+r},${+g},${+b}`
}

export const formatValue = (value) =>
  Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'KSH',
    maximumSignificantDigits: 3,
    notation: 'compact'
  }).format(value)

export const setItemToLocalStorage = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value))

export const getItemFromLocalStorage = (key) => JSON.parse(localStorage.getItem(key))

export const setCookie = ({ key, value, options }) =>
  Cookies.set(key, JSON.stringify(value), options)

export const getCookie = (key) => Cookies.get(key)

export const removeCookie = (key, options) => Cookies.remove(key, options)

export const getUserDetails = () => {
  const user = !isNil(getCookie('user')) ? JSON.parse(getCookie('user')) : {}
  return user
}

export const decodeToken = (token) => jwtDecode(token)

export const getParamFromUrl = ({ url, param }) => {
  const formattedUrl = new URL(url)
  const params = new URLSearchParams(formattedUrl.search)

  return params.get(param)
}

export const objectToQueryString = (params) =>
  Object.keys(params)
    .map((key) => key + '=' + params[key])
    .join('&')

export const formatPhoneNumber = (phone, countryCode) => {
  let number = phone
  if (number.charAt(0) === '+') {
    number = number.substr(1)
  }

  if (number.substr(3).charAt(0) === '0') {
    number = number.substr(0, 3) + number.substr(4)
  }

  if (number.charAt(0) === '0') {
    number = countryCode + number.substr(1)
  }

  if (number.charAt(0) === '7') {
    number = countryCode + number
  }

  return number
}

export const formatTimestampToDate = (date) => new Date(date).toLocaleDateString('en-GB')

export const formatDateToTime = (date) => {
  const formattedDate = new Date(date).toTimeString()
  const time = head(formattedDate.split(' '))
  return time
}

export const getDayFromDate = (date) => {
  const formattedDate = new Date(date).toDateString()
  const arrays = formattedDate.split(' ')
  return head(arrays)
}

export const transformTransactionType = (type) => {
  const newType = type.split('_')
  return newType[0] + ' ' + newType[1]
}

export const amountDue = (amountBorrowed, plan) => {
  const charges = Object.values(chargesConfig)
  const interests = props([plan], loanInterestConfig)
  return (
    Number(amountBorrowed) +
    sum(map((x) => multiply(amountBorrowed / 100, x), [...charges, ...interests]))
  )
}

export const interestCalculator = (plan) => {
  const newArr = props([plan], loanInterestConfig)

  return newArr[newArr.length - 1]
}

export const addDaysToDate = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + Number(days))
  return result.toLocaleDateString('en-GB')
}

export const snakeCase = (string) =>
  string
    .replace(/\d+/g, ' ')
    .split(/ |\B(?=[A-Z])/)
    .map((word) => word.toLowerCase())
    .join('_')

export const textCase = (string) =>
  string
    .replace('_', ' ')
    .split('_')
    .map((word) => word.toLowerCase())
    .join(' ')

export const formatChatDate = (timestamp) => {
  let date = new Date(timestamp).toDateString()
  date = date.split(' ')
  return `${date[2]} ${date[1]}`
}

export const formatAmount = (amount) => {
  if (Number.isInteger(amount)) {
    return amount
  } else if (isNaN(amount)) {
    return 0
  } else {
    return Number(amount).toFixed(2)
  }
}

export const identifyUser = (userId, traits) => window.analytics.identify(userId, traits)

export const trackEvent = (eventName, properties) =>
  window.analytics.track(eventName, properties)

export const computeInterestBreakdown = (interest) => {
  const exciseDuty = Number(0.05 * interest).toFixed(2)
  const govtTax = Number(0.2 * interest).toFixed(2)
  const digitalTax = Number(0.05 * interest).toFixed(2)

  return { exciseDuty, govtTax, digitalTax }
}

export const omitEmptyValues = (obj) => reject(anyPass([isEmpty, isNil]))(obj)

export const formatToHumanDate = (date) => moment(date).format('Do MMM YYYY')

export const truncateMessage = (message) => {
  return message && message.substring(0, 30).replace(/[\u{0080}-\u{FFFF}]/gu, '')
}
