import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Dialog, Transition } from '@headlessui/react'
import { Link, useLocation } from 'react-router-dom'
import {
  ClockIcon,
  CogIcon,
  CreditCardIcon,
  DocumentChartBarIcon,
  HomeIcon,
  ScaleIcon,
  UserGroupIcon,
  XMarkIcon,
  BanknotesIcon,
  BuildingStorefrontIcon,
  ArchiveBoxIcon
} from '@heroicons/react/24/outline'
import { icons } from '../../assets/img'
import {
  ANALYTICS,
  LOANS,
  OVERVIEW,
  PREMISES,
  SYSTEM_SETTINGS,
  TRANSACTIONS,
  USERS,
  VOUCHERS,
  WITHDRAWAL_REQUESTS,
  INITIATIVES
} from '../../containers/App/RouteConstants'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation()
  const navigation = [
    {
      name: 'Home',
      href: OVERVIEW,
      icon: HomeIcon,
      current: location.pathname === OVERVIEW
    },
    {
      name: 'Users',
      href: USERS,
      icon: UserGroupIcon,
      current: location.pathname === USERS
    },
    { name: 'Loans', href: LOANS, icon: ScaleIcon, current: location.pathname === LOANS },
    {
      name: 'Premises',
      href: PREMISES,
      icon: BuildingStorefrontIcon,
      current: location.pathname === PREMISES
    },
    {
      name: 'Transactions',
      href: TRANSACTIONS,
      icon: CreditCardIcon,
      current: location.pathname === TRANSACTIONS
    },
    {
      name: 'Vouchers',
      href: VOUCHERS,
      icon: ClockIcon,
      current: location.pathname === VOUCHERS
    },
    {
      name: 'Initiatives',
      href: INITIATIVES,
      icon: ArchiveBoxIcon,
      current: location.pathname === INITIATIVES
    },
    {
      name: 'Withdrawal Requests',
      href: WITHDRAWAL_REQUESTS,
      icon: BanknotesIcon,
      current: location.pathname === WITHDRAWAL_REQUESTS
    },
    {
      name: 'Reports & Analytics',
      href: ANALYTICS,
      icon: DocumentChartBarIcon,
      current: location.pathname === ANALYTICS
    }
  ]
  const secondaryNavigation = [
    {
      name: 'System Settings',
      href: SYSTEM_SETTINGS,
      icon: CogIcon,
      current: location.pathname === SYSTEM_SETTINGS
    }
  ]

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as='div' className='relative z-40 lg:hidden' onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-linear duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-300'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-gray-600 bg-opacity-75' />
          </Transition.Child>

          <div className='fixed inset-0 z-40 flex'>
            <Transition.Child
              as={Fragment}
              enter='transition ease-in-out duration-300 transform'
              enterFrom='-translate-x-full'
              enterTo='translate-x-0'
              leave='transition ease-in-out duration-300 transform'
              leaveFrom='translate-x-0'
              leaveTo='-translate-x-full'
            >
              <Dialog.Panel className='relative flex w-full max-w-xs flex-1 flex-col bg-gray-700 pt-5 pb-4'>
                <Transition.Child
                  as={Fragment}
                  enter='ease-in-out duration-300'
                  enterFrom='opacity-0'
                  enterTo='opacity-100'
                  leave='ease-in-out duration-300'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                >
                  <div className='absolute top-0 right-0 -mr-12 pt-2'>
                    <button
                      type='button'
                      className='ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className='sr-only'>Close sidebar</span>
                      <XMarkIcon className='h-6 w-6 text-white' aria-hidden='true' />
                    </button>
                  </div>
                </Transition.Child>
                <div className='flex flex-shrink-0 items-center px-4'>
                  <Link to={OVERVIEW}>
                    <img
                      className='h-12 w-auto'
                      src={icons.logoWhite}
                      alt='Paylend Logo'
                    />
                  </Link>
                </div>
                <nav
                  className='mt-5 h-full flex-shrink-0 divide-y divide-gray-800 overflow-y-auto'
                  aria-label='Sidebar'
                >
                  <div className='space-y-1 px-2'>
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.current
                            ? 'bg-gray-800 text-white'
                            : 'text-gray-100 hover:text-white hover:bg-gray-600',
                          'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        <item.icon
                          className='mr-4 h-6 w-6 flex-shrink-0 text-gray-200'
                          aria-hidden='true'
                        />
                        {item.name}
                      </Link>
                    ))}
                  </div>
                  <div className='mt-6 pt-6'>
                    <div className='space-y-1 px-2'>
                      {secondaryNavigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className='group flex items-center rounded-md px-2 py-2 text-base font-medium text-gray-100 hover:bg-gray-600 hover:text-white'
                        >
                          <item.icon
                            className='mr-4 h-6 w-6 text-gray-200'
                            aria-hidden='true'
                          />
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </nav>
              </Dialog.Panel>
            </Transition.Child>
            <div className='w-14 flex-shrink-0' aria-hidden='true'>
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className='hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col'>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex flex-grow flex-col overflow-y-auto bg-gray-700 pt-5 pb-4'>
          <div className='flex flex-shrink-0 items-center px-4 justify-center'>
            <Link to={OVERVIEW}>
              <img className='h-12 w-auto' src={icons.logoWhite} alt='Paylend Logo' />
            </Link>
          </div>
          <nav
            className='mt-5 flex flex-1 flex-col divide-y divide-gray-800 overflow-y-auto'
            aria-label='Sidebar'
          >
            <div className='space-y-1 px-2'>
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-800 text-white'
                      : 'text-gray-100 hover:text-white hover:bg-gray-600',
                    'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  <item.icon
                    className='mr-4 h-6 w-6 flex-shrink-0 text-gray-200'
                    aria-hidden='true'
                  />
                  {item.name}
                </Link>
              ))}
            </div>
            <div className='mt-6 pt-6'>
              <div className='space-y-1 px-2'>
                {secondaryNavigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className='group flex items-center rounded-md px-2 py-2 text-sm font-medium leading-6 text-gray-100 hover:bg-gray-600 hover:text-white'
                  >
                    <item.icon
                      className='mr-4 h-6 w-6 text-gray-200'
                      aria-hidden='true'
                    />
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

Sidebar.propTypes = {
  sidebarOpen: PropTypes.bool,
  setSidebarOpen: PropTypes.func
}

export default Sidebar
