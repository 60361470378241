import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { icons } from '../../../assets/img'
import { AuthLayout, Button, FormGroup, Input } from '../../../components'
import config from '../../../helpers/config'
import { loginUser } from '../store'

function Login() {
  const dispatch = useDispatch()
  const { isLoading, errors, shouldResetForm } = useSelector((state) => state.auth)

  const [state, setState] = useState({
    login_type: 'email',
    email: '',
    password: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const onLogin = () => {
    dispatch(loginUser(state))
  }

  useEffect(() => {
    shouldResetForm &&
      setState({
        login_type: 'email',
        email: '',
        password: ''
      })
  }, [shouldResetForm])

  return (
    <AuthLayout
      pageTitle='Log In'
      title='Sign in to your account'
      subTitle='Enter your email address and password to continue'
      content={
        <>
          <FormGroup>
            <Input
              type='text'
              name='email'
              placeholder={'Email address'}
              onChange={handleChange}
              value={state.email}
              inputLabel={'Email address'}
              hasError={Object.keys(errors).includes('email')}
              errorMessage={errors.email}
            />
          </FormGroup>
          <FormGroup>
            <Input
              type='password'
              placeholder={'Password'}
              onChange={handleChange}
              fieldType={'password'}
              name='password'
              value={state.password}
              inputLabel={'Password'}
              hasError={Object.keys(errors).includes('password')}
              errorMessage={errors.password}
            />
          </FormGroup>
          <div className='flex items-center justify-between mb-4'>
            <div className='flex items-center'>
              <Input
                type='checkbox'
                checkboxLabel='Remember me'
                name='remember'
                onChange={() => null}
              />
            </div>
            <div className='text-sm'>
              <a
                href={`${config('CUSTOMER_DASHBOARD_URL')}/forgot-password`}
                className='font-medium text-secondary-600 hover:text-secondary-500 -mt-2 inline-block'
                target={'_blank'}
                rel='noreferrer'
              >
                Forgot your password?
              </a>
            </div>
          </div>
          <Button label='Sign in' onClick={onLogin} disabled={isLoading} type='primary' />
        </>
      }
      illustration={icons.login}
    />
  )
}

export default Login
