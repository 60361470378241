export const INDEX = '/'
export const LOGIN = '/login'
export const OVERVIEW = '/overview'
export const FORGOT_PASSWORD = '/forgot-password'
export const RESET_PASSWORD = '/reset-password'
export const USERS = '/users'
export const LOANS = '/loans'
export const PREMISES = '/premises'
export const TRANSACTIONS = '/transactions'
export const VOUCHERS = '/vouchers'
export const WITHDRAWAL_REQUESTS = '/withdrawal-requests'
export const ANALYTICS = '/analytics'
export const SYSTEM_SETTINGS = '/system-settings'
export const PROFILE = '/profile'
export const INITIATIVES = '/initiatives'
