import { useState } from 'react'
import PropTypes from 'prop-types'

function Input({
  onChange,
  type,
  placeholder,
  hasError,
  checkboxLabel,
  fieldType,
  styles,
  name,
  classes,
  icon,
  errorMessage,
  value,
  onBlur,
  inputRef,
  id,
  accept,
  inputLabel
}) {
  const [showPassword, setShowPassword] = useState(false)

  if (type === 'checkbox') {
    return (
      <div className='inline-flex items-center'>
        <input
          type={type}
          className='text-secondary-600 ring-0 w-4 h-4 focus:ring-0 outline-none rounded'
          onChange={onChange}
          style={styles}
          name={name}
          onBlur={onBlur}
          ref={inputRef}
          id={id}
        />
        <label htmlFor={name} className='ml-2 block text-sm text-gray-900'>
          {checkboxLabel}
        </label>
      </div>
    )
  } else if (type === 'password' || fieldType === 'password') {
    return (
      <>
        <div className='relative'>
          <label htmlFor={name} className='block text-sm font-medium text-gray-700 mb-2'>
            {inputLabel}
          </label>
          <input
            type={showPassword ? 'text' : 'password'}
            className={`block w-full appearance-none rounded-md border ${
              hasError ? 'border-red-300' : 'border-gray-300'
            } px-3 py-2 placeholder-gray-400 shadow-sm ${
              hasError ? 'focus:border-red-500' : 'focus:border-secondary-500'
            } focus:outline-none focus:ring-secondary-500 sm:text-sm ${classes}`}
            placeholder={placeholder}
            onChange={onChange}
            style={styles}
            name={name}
            value={value}
            onBlur={onBlur}
            id={id}
          />
          <div className='absolute inset-y-0 top-7 right-0 pr-3 flex items-center text-sm leading-5'>
            {showPassword ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 cursor-pointer text-gray-500'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                onClick={() => setShowPassword(false)}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21'
                />
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 cursor-pointer text-gray-500'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                onClick={() => setShowPassword(true)}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M15 12a3 3 0 11-6 0 3 3 0 016 0z'
                />
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z'
                />
              </svg>
            )}
          </div>
        </div>
        <span className='text-sm font-gotham-rounded-book block text-red-600'>
          {errorMessage}
        </span>
      </>
    )
  } else {
    return (
      <>
        <div className='relative'>
          <label htmlFor={name} className='block text-sm font-medium text-gray-700 mb-2'>
            {inputLabel}
          </label>
          <input
            type={type}
            className={`block w-full appearance-none rounded-md border ${
              hasError ? 'border-red-300' : 'border-gray-300'
            } px-3 py-2 placeholder-gray-400 shadow-sm ${
              hasError ? 'focus:border-red-500' : 'focus:border-secondary-500'
            } focus:outline-none focus:ring-secondary-500 sm:text-sm ${classes}`}
            placeholder={placeholder}
            onChange={onChange}
            style={styles}
            name={name}
            value={value}
            onBlur={onBlur}
            ref={inputRef}
            id={id}
            accept={accept}
          />
          {icon && (
            <div className='absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5 justify-between'>
              {icon}
            </div>
          )}
        </div>
        <span className='text-sm font-gotham-rounded-book block text-red-600'>
          {errorMessage}
        </span>
      </>
    )
  }
}

Input.defaultProps = {
  type: 'text'
}

Input.propTypes = {
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  hasError: PropTypes.any,
  type: PropTypes.string,
  checkboxLabel: PropTypes.string,
  fieldType: PropTypes.string,
  styles: PropTypes.object,
  name: PropTypes.string,
  classes: PropTypes.string,
  icon: PropTypes.node,
  phoneStyles: PropTypes.string,
  errorMessage: PropTypes.string,
  phoneCode: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
  inputRef: PropTypes.object,
  id: PropTypes.string,
  accept: PropTypes.string,
  inputLabel: PropTypes.string
}

export default Input
