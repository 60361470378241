export const users = () => ({
  data: [
    {
      id: '6414144b8295c8e57621bc7e',
      firstname: 'james',
      lastname: 'kiragu',
      email: 'wakamam@gmail.com',
      phone: '254704709658',
      status: 'Active',
      date_of_birth: '',
      occupation: '',
      income: null,
      identification: '24653034',
      gender: '',
      email_verified: false,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-17T07:18:35.361Z',
      updated_at: '2023-03-17T07:19:17.835Z'
    },
    {
      id: '641405408295c8e57621b706',
      firstname: 'Daniel',
      lastname: 'Mwanzia',
      email: 'daniellmwanzia@gmail.com',
      phone: '254728381009',
      status: 'Active',
      date_of_birth: '',
      occupation: '',
      income: null,
      identification: '28677804',
      gender: '',
      email_verified: true,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-17T06:14:24.201Z',
      updated_at: '2023-03-17T06:16:45.712Z'
    },
    {
      id: '641402548295c8e57621b5ec',
      firstname: 'Edson',
      lastname: 'Misheba',
      email: 'emisheba@gmail.com',
      phone: '254769072189',
      status: 'Active',
      date_of_birth: '',
      occupation: '',
      income: null,
      identification: '24426221',
      gender: '',
      email_verified: true,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-17T06:01:56.881Z',
      updated_at: '2023-03-17T06:06:40.278Z'
    },
    {
      id: '6413f0a28295c8e57621b2c3',
      firstname: 'Intiswar',
      lastname: 'Said',
      email: 'intiswarsaid72@gmail.com',
      phone: '254795987425',
      status: 'Active',
      date_of_birth: '',
      occupation: '',
      income: null,
      identification: '30546954',
      gender: '',
      email_verified: false,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-17T04:46:26.345Z',
      updated_at: '2023-03-17T04:48:25.343Z'
    },
    {
      id: '6413a85d8295c8e57621ae8d',
      firstname: 'Doreen',
      lastname: 'Kisaro',
      email: 'aishaseleina@gmail.com',
      phone: '254722982820',
      status: 'Active',
      date_of_birth: '',
      occupation: '',
      income: null,
      identification: '24685074',
      gender: '',
      email_verified: true,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-16T23:38:05.019Z',
      updated_at: '2023-03-16T23:39:34.202Z'
    },
    {
      id: '6413763c8295c8e57621a9a5',
      firstname: 'Kelvin',
      lastname: 'Muthoni',
      email: 'kevinnjogumail@gmail.com',
      phone: '254722908505',
      status: 'Active',
      date_of_birth: 'Tue Aug 04 1992 03:00:00 GMT+0300 (East Africa Time)',
      occupation: 'Business owner',
      income: 50000,
      identification: '30195571',
      gender: 'male',
      email_verified: true,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-16T20:04:12.519Z',
      updated_at: '2023-03-16T20:08:37.903Z'
    },
    {
      id: '64136f2c8295c8e57621a963',
      firstname: 'Anne',
      lastname: 'Wanjiru',
      email: 'annewanjirugitonga@gmail.com',
      phone: '254723301988',
      status: 'Active',
      date_of_birth: '',
      occupation: '',
      income: null,
      identification: '25135336',
      gender: '',
      email_verified: false,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-16T19:34:04.639Z',
      updated_at: '2023-03-16T19:34:48.446Z'
    },
    {
      id: '641368108295c8e57621a81e',
      firstname: 'Simeon',
      lastname: 'Kanyi',
      email: 'kanyimfalme@gmail.com',
      phone: '254720465100',
      status: 'Active',
      date_of_birth: '',
      occupation: '',
      income: null,
      identification: '25113230',
      gender: '',
      email_verified: true,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-16T19:03:44.435Z',
      updated_at: '2023-03-16T19:08:10.293Z'
    },
    {
      id: '641366c38295c8e57621a76d',
      firstname: 'Martha',
      lastname: 'Murigi',
      email: 'marthawanji9@gmail.com',
      phone: '254743495761',
      status: 'Active',
      date_of_birth: '',
      occupation: '',
      income: null,
      identification: '36922898',
      gender: '',
      email_verified: true,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-16T18:58:11.553Z',
      updated_at: '2023-03-16T18:59:45.142Z'
    },
    {
      id: '64135ed18295c8e57621a5ab',
      firstname: 'Peter',
      lastname: 'Nganga',
      email: 'petergitau202@gmail.com',
      phone: '254723374949',
      status: 'Active',
      date_of_birth: '',
      occupation: '',
      income: null,
      identification: '22444521',
      gender: '',
      email_verified: true,
      phone_verified: true,
      language: 'english',
      country: 'Kenya',
      roles: 'customer',
      old_user_id: null,
      old_user: false,
      created_at: '2023-03-16T18:24:17.784Z',
      updated_at: '2023-03-16T18:34:47.383Z'
    }
  ],
  metadata: {
    count: 6964
  }
})

export const loans = () => ({
  data: [
    {
      id: '64141f53a848e55436bc3413',
      user_id: '6409f79b2efcb08e63c6a716',
      premise_owner_id: '6409f79b2efcb08e63c6a716',
      loan_type: 'inua_biashara',
      premise_name: 'John mtush',
      beneficiary_name: '',
      paylend_number: '171762',
      repayment_plan: 14,
      interest: 200,
      amount_borrowed: 2000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 2200,
      due_date: '',
      formated_due_date: '',
      date_approved_declined: '',
      status: 'Business pending',
      confirmation_by_user: true,
      transaction_code: '1679040339234',
      created_at: '2023-03-17T07:42:45.175Z',
      updated_at: '2023-03-17T07:42:45.176Z'
    },
    {
      id: '6413e7d48295c8e57621b152',
      user_id: '6409bd7c2efcb08e63c67823',
      premise_owner_id: '6409bd7c2efcb08e63c67823',
      loan_type: 'inua_biashara',
      premise_name: 'John designs',
      beneficiary_name: '',
      paylend_number: '689521',
      repayment_plan: 14,
      interest: 200,
      amount_borrowed: 2000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 2200,
      due_date: '2023-03-31T11:13:04+03:00',
      formated_due_date: '31/03/2023',
      date_approved_declined: '15/03/2023',
      status: 'Business accepted',
      confirmation_by_user: true,
      transaction_code: '1679026132510',
      created_at: '2023-03-15T10:54:49.076Z',
      updated_at: '2023-03-15T10:54:49.077Z'
    },
    {
      id: '64133a6e8295c8e5762199c2',
      user_id: '641054cd1a635e6c346d6919',
      premise_owner_id: '641054cd1a635e6c346d6919',
      loan_type: 'inua_biashara',
      premise_name: 'Njeri mtumba',
      beneficiary_name: '',
      paylend_number: '783557',
      repayment_plan: 30,
      interest: 400,
      amount_borrowed: 2000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 2400,
      due_date: '',
      formated_due_date: '',
      date_approved_declined: '',
      status: 'Business pending',
      confirmation_by_user: true,
      transaction_code: '1678981742077',
      created_at: '2023-03-15T10:54:49.076Z',
      updated_at: '2023-03-15T10:54:49.077Z'
    },
    {
      id: '64131c068295c8e576217fc1',
      user_id: '641315938295c8e576217b26',
      premise_owner_id: '641315938295c8e576217b26',
      loan_type: 'inua_biashara',
      premise_name: 'Faith Shop',
      beneficiary_name: '',
      paylend_number: '802853',
      repayment_plan: 30,
      interest: 400,
      amount_borrowed: 2000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 2400,
      due_date: '2023-04-16T11:16:47+03:00',
      formated_due_date: '16/04/2023',
      date_approved_declined: '15/03/2023',
      status: 'Business accepted',
      confirmation_by_user: true,
      transaction_code: '1678973958237',
      created_at: '2023-03-15T10:54:49.076Z',
      updated_at: '2023-03-15T10:54:49.077Z'
    },
    {
      id: '641302478295c8e5762169a8',
      user_id: '63e39e08c62e1cdfbba51c17',
      premise_owner_id: '63e39e08c62e1cdfbba51c17',
      loan_type: 'inua_biashara',
      premise_name: 'Nunga best health care',
      beneficiary_name: '',
      paylend_number: '876382',
      repayment_plan: 30,
      interest: 1000,
      amount_borrowed: 5000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 6000,
      due_date: '2023-04-16T10:14:58+03:00',
      formated_due_date: '16/04/2023',
      date_approved_declined: '15/03/2023',
      status: 'Business accepted',
      confirmation_by_user: true,
      transaction_code: '1678967367319',
      created_at: '2023-03-15T10:54:49.076Z',
      updated_at: '2023-03-15T10:54:49.077Z'
    },
    {
      id: '6412f2678295c8e576215c21',
      user_id: '6412ef3c8295c8e5762156ca',
      premise_owner_id: '6412ef3c8295c8e5762156ca',
      loan_type: 'inua_biashara',
      premise_name: 'Michelle hotel',
      beneficiary_name: '',
      paylend_number: '249814',
      repayment_plan: 14,
      interest: 200,
      amount_borrowed: 2000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 2200,
      due_date: '',
      formated_due_date: '',
      date_approved_declined: '',
      status: 'Business pending',
      confirmation_by_user: true,
      transaction_code: '1678963303398',
      created_at: '2023-03-15T10:54:49.076Z',
      updated_at: '2023-03-15T10:54:49.077Z'
    },
    {
      id: '6412ee078295c8e576215628',
      user_id: '6328883501936785ec128723',
      premise_owner_id: '6328883501936785ec128723',
      loan_type: 'inua_biashara',
      premise_name: 'Shilos book shop',
      beneficiary_name: '',
      paylend_number: '798724',
      repayment_plan: 30,
      interest: 3000,
      amount_borrowed: 15000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 18000,
      due_date: '2023-04-16T10:10:00+03:00',
      formated_due_date: '16/04/2023',
      date_approved_declined: '15/03/2023',
      status: 'Business accepted',
      confirmation_by_user: true,
      transaction_code: '1678962183764',
      created_at: '2023-03-15T10:54:49.076Z',
      updated_at: '2023-03-15T10:54:49.077Z'
    },
    {
      id: '641294cc8295c8e576212782',
      user_id: '641061651a635e6c346d7706',
      premise_owner_id: '641061651a635e6c346d7706',
      loan_type: 'inua_biashara',
      premise_name: 'Atieno fish Stall 335',
      beneficiary_name: '',
      paylend_number: '584571',
      repayment_plan: 30,
      interest: 400,
      amount_borrowed: 2000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 2400,
      due_date: '',
      formated_due_date: '',
      date_approved_declined: '',
      status: 'Business pending',
      confirmation_by_user: true,
      transaction_code: '1678939340497',
      created_at: '2023-03-15T10:54:49.076Z',
      updated_at: '2023-03-15T10:54:49.077Z'
    },
    {
      id: '6412011f8295c8e57621150f',
      user_id: '6408b2a15be53bca05d4c98a',
      premise_owner_id: '6408b2a15be53bca05d4c98a',
      loan_type: 'inua_biashara',
      premise_name: 'Mbithi hotel ',
      beneficiary_name: '',
      paylend_number: '729123',
      repayment_plan: 60,
      interest: 800,
      amount_borrowed: 2000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 2800,
      due_date: '2023-05-15T11:57:27+03:00',
      formated_due_date: '15/05/2023',
      date_approved_declined: '15/03/2023',
      status: 'Business accepted',
      confirmation_by_user: true,
      transaction_code: '1678901535578',
      created_at: '2023-03-15T10:54:49.076Z',
      updated_at: '2023-03-15T10:54:49.077Z'
    },
    {
      id: '6411eba58295c8e5762105c0',
      user_id: '640712535be53bca05d3eb4d',
      premise_owner_id: '640712535be53bca05d3eb4d',
      loan_type: 'inua_biashara',
      premise_name: 'Calvin tairong',
      beneficiary_name: '',
      paylend_number: '737622',
      repayment_plan: 60,
      interest: 800,
      amount_borrowed: 2000,
      fine_applied: false,
      old_loan: false,
      currency: 'KES',
      amount_due: 2800,
      due_date: '2023-05-15T13:43:07+03:00',
      formated_due_date: '15/05/2023',
      date_approved_declined: '15/03/2023',
      status: 'Business accepted',
      confirmation_by_user: true,
      transaction_code: '1678896037235',
      created_at: '2023-03-15T10:54:49.076Z',
      updated_at: '2023-03-15T10:54:49.077Z'
    }
  ],
  metadata: {
    count: 2288
  }
})

export const premises = () => ({
  data: [
    {
      id: '64143368fbea4ecbc4ab116f',
      user_id: '6410524b1a635e6c346d673c',
      business_permit:
        'https://paylendafrica.s3.fr-par.scw.cloud/b4be98ab-5584-4136-9051-af0168d203fe.jpg',
      location: {
        type: 'Point',
        coordinates: [36.8411671, -1.2855959]
      },
      premise_address: 'Kamukunji Road, Nairobi, Kenya',
      manager_firstname: 'Elkana',
      manager_lastname: 'Ogutu',
      manager_identification: 'ac55199b-66b4-418e-ba3a-9f7f5799127b.jpg',
      manager_phone_number: '254725208617',
      manager_email: 'elkanaogutu@gmail.com',
      monthly_transaction_income: 40000,
      mpesa_statement: 'f33433da-0a61-40d3-9e54-658d308f1d3f.jpg',
      paylend_number: '393372',
      premise_front_image:
        'https://paylendafrica.s3.fr-par.scw.cloud/23c37bd0-e4f3-439a-9e3f-5c3e1e478788.jpg',
      premise_name: 'Shalom shop',
      premise_type: 'shop',
      is_manager: true,
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: false,
      created_at: '2023-03-17T09:31:20.733Z',
      updated_at: '2023-03-17T09:31:20.733Z'
    },
    {
      id: '641378b68295c8e57621ac43',
      user_id: '633eb88c3d810d86b9c67321',
      business_permit: '',
      monthly_transaction_income: 0,
      paylend_number: '056086',
      premise_front_image: '',
      premise_name: 'Minimart',
      premise_type: 'shop',
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: false,
      verified_by: null,
      created_at: '2023-03-16T20:14:46.899Z',
      updated_at: '2023-03-16T20:14:46.899Z'
    },
    {
      id: '641337368295c8e5762196cf',
      user_id: '641335188295c8e576219503',
      business_permit:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/bc66a721-ad92-4315-b4d4-ed6f38a84898.jpg',
      location: {
        type: 'Point',
        coordinates: [-1.2920659, 36.8219462]
      },
      premise_address: 'Nairobi, Kenya',
      manager_firstname: 'Jane',
      manager_lastname: 'Maina',
      manager_identification: '31245632',
      manager_phone_number: '254705547936',
      manager_email: 'njugunacharles367@gmail.com',
      monthly_transaction_income: 30000,
      mpesa_statement:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/28517f87-0a94-40b3-9c6d-8965f866ad1f.jpg',
      paylend_number: '515863',
      premise_front_image:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/a9aa6507-3306-409f-937a-961dfa3da059.jpg',
      premise_name: 'Jane designs',
      premise_type: 'shop',
      is_manager: true,
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: false,
      created_at: '2023-03-16T15:35:18.065Z',
      updated_at: '2023-03-16T15:35:18.065Z'
    },
    {
      id: '6413282b8295c8e576218c61',
      user_id: '641091631a635e6c346da9d1',
      business_permit:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/928e01d3-77b5-4ff4-a6cf-423595a49240.jpg',
      location: {
        type: 'Point',
        coordinates: [-1.2920659, 36.8219462]
      },
      premise_address: 'Nairobi, Kenya',
      manager_firstname: 'Susan',
      manager_lastname: 'Omondi',
      manager_identification: '33737252',
      manager_phone_number: '254758220118',
      manager_email: 'somondi721@gmail.com',
      monthly_transaction_income: 30000,
      mpesa_statement:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/3e0a20d2-7141-4496-a462-8c9829d2706a.jpg',
      paylend_number: '466072',
      premise_front_image:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/2c29661d-1c47-45d7-8c7d-bcd386141bbe.jpg',
      premise_name: 'Suzy Fashion',
      premise_type: 'shop',
      is_manager: true,
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: true,
      verifying_agent: {
        _id: '630c6bf0ec1a4a20265a1c20',
        firstname: 'Eric',
        lastname: 'Gitonga',
        phone: '254722615937',
        email: 'christianerics7@gmail.com',
        status: 'Active',
        identification: '24706379',
        dob: 'Thu Feb 07 1985 13:52:30 GMT+0000 (Coordinated Universal Time)',
        occupation: 'Realtor',
        gender: 'Male',
        income: 70000,
        roles: 'agent',
        email_verified: false,
        phone_verified: true,
        ussd_verified: false,
        profile_pic: null,
        language: 'english',
        country: 'Kenya',
        old_user_id: 9786,
        user_number: 'PLUN764295',
        referral_id: null,
        geolocation: null,
        join_date: '2022-04-07T06:48:57.000Z',
        description: null,
        old_user: true,
        nationality: null,
        identification_picture_front: null,
        identification_picture_back: null,
        marital_status: null,
        employment_status: null,
        residence: null,
        referral_count: 16,
        has_migrated_data: true,
        created_at: '2022-08-29T07:34:08.156Z',
        updated_at: '2023-03-16T13:31:41.749Z',
        __v: 0,
        password: '$2b$10$AafCrLZohru5kpA6zH661.lkCU/Px8mq.VmxXTfCo.zSR9QMtuNGS',
        wallet_updated: true
      },
      created_at: '2023-03-16T14:31:07.291Z',
      updated_at: '2023-03-16T14:32:05.628Z'
    },
    {
      id: '6413194a8295c8e576217dc9',
      user_id: '641315938295c8e576217b26',
      business_permit:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/9774664c-7b19-4e06-801d-0c100b6a78de.jpg',
      location: {
        type: 'Point',
        coordinates: [-1.2920659, 36.8219462]
      },
      premise_address: 'Nairobi, Kenya',
      manager_firstname: 'Faith',
      manager_lastname: 'Achola',
      manager_identification: '38018224',
      manager_phone_number: '254111751760',
      manager_email: 'faithachola02@gmail.com',
      monthly_transaction_income: 40000,
      mpesa_statement:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/d04c7b36-171f-4042-937a-3834b7306b37.jpg',
      paylend_number: '802853',
      premise_front_image:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/c5f44133-2593-41f0-b741-835c2cc5b928.jpg',
      premise_name: 'Faith Shop',
      premise_type: 'shop',
      is_manager: true,
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: true,
      verifying_agent: {
        _id: '630c6bf0ec1a4a20265a1c20',
        firstname: 'Eric',
        lastname: 'Gitonga',
        phone: '254722615937',
        email: 'christianerics7@gmail.com',
        status: 'Active',
        identification: '24706379',
        dob: 'Thu Feb 07 1985 13:52:30 GMT+0000 (Coordinated Universal Time)',
        occupation: 'Realtor',
        gender: 'Male',
        income: 70000,
        roles: 'agent',
        email_verified: false,
        phone_verified: true,
        ussd_verified: false,
        profile_pic: null,
        language: 'english',
        country: 'Kenya',
        old_user_id: 9786,
        user_number: 'PLUN764295',
        referral_id: null,
        geolocation: null,
        join_date: '2022-04-07T06:48:57.000Z',
        description: null,
        old_user: true,
        nationality: null,
        identification_picture_front: null,
        identification_picture_back: null,
        marital_status: null,
        employment_status: null,
        residence: null,
        referral_count: 16,
        has_migrated_data: true,
        created_at: '2022-08-29T07:34:08.156Z',
        updated_at: '2023-03-16T13:31:41.749Z',
        __v: 0,
        password: '$2b$10$AafCrLZohru5kpA6zH661.lkCU/Px8mq.VmxXTfCo.zSR9QMtuNGS',
        wallet_updated: true
      },
      created_at: '2023-03-16T13:27:38.506Z',
      updated_at: '2023-03-16T13:32:26.341Z'
    },
    {
      id: '64130fc58295c8e5762178f3',
      user_id: '64130ec78295c8e5762177e0',
      business_permit:
        'https://paylendafrica.s3.fr-par.scw.cloud/e6b57a32-94c3-4a91-b20c-cbc2b49b83c8.jpg',
      location: {
        type: 'Point',
        coordinates: [36.8721139, -1.2952979]
      },
      premise_address: 'Makadara, Nairobi, Kenya',
      manager_firstname: 'Rabbeca',
      manager_lastname: 'Stuma',
      manager_identification: 'a58a53e4-8238-48a5-a642-1c6dbed9af23.jpg',
      manager_phone_number: '254703244216',
      manager_email: 'rabbecastuma@gmail.com',
      monthly_transaction_income: 30000,
      mpesa_statement: '7b6b55ec-28db-4813-8de2-1bb6b0bbcb18.jpg',
      paylend_number: '928786',
      premise_front_image:
        'https://paylendafrica.s3.fr-par.scw.cloud/183a9dec-67f5-46cc-8904-acb040a6d3d6.jpg',
      premise_name: 'Rabbeca salon',
      premise_type: 'shop',
      is_manager: true,
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: true,
      verifying_agent: {
        _id: '630c6b19ec1a4a20265a1942',
        firstname: 'Damaris',
        lastname: 'Muithya',
        phone: '254706507970',
        email: 'nthenyad496@gmail.com',
        status: 'Active',
        identification: '24549439',
        dob: 'Tue Apr 15 1986 09:52:31 GMT+0000 (Coordinated Universal Time)',
        occupation: 'Accountant',
        gender: 'Female',
        income: 30000,
        roles: 'agent',
        email_verified: true,
        phone_verified: true,
        ussd_verified: false,
        profile_pic: null,
        language: 'english',
        country: 'Kenya',
        old_user_id: 9787,
        user_number: 'PLUN960102',
        referral_id: null,
        geolocation: null,
        join_date: '2022-04-07T06:52:35.000Z',
        description: null,
        old_user: true,
        nationality: null,
        identification_picture_front: null,
        identification_picture_back: null,
        marital_status: null,
        employment_status: null,
        residence: null,
        referral_count: 19,
        has_migrated_data: true,
        created_at: '2022-08-29T07:30:33.003Z',
        updated_at: '2023-01-16T06:30:46.008Z',
        __v: 0,
        password: '$2b$10$8w1G3sF75y2X2aJRsDRm8OmX60ozlPh2h4Ri0EFMZAkBAYzky3Nem',
        wallet_updated: true
      },
      created_at: '2023-03-16T12:47:01.650Z',
      updated_at: '2023-03-16T12:47:53.582Z'
    },
    {
      id: '64130afe8295c8e5762176ce',
      user_id: '64130a228295c8e5762175d0',
      business_permit:
        'https://paylendafrica.s3.fr-par.scw.cloud/e5543086-78de-47f5-9055-0a4ba0886c1a.jpg',
      location: {
        type: 'Point',
        coordinates: [36.8815479, -1.2922481]
      },
      premise_address: 'Buruburu, Nairobi, Kenya',
      manager_firstname: 'Daniel',
      manager_lastname: 'Kigutia',
      manager_identification: '71f7230a-5b77-46fd-b1ca-e9f865d861c2.jpg',
      manager_phone_number: '254798072675',
      manager_email: 'danielgutia@gmail.com',
      monthly_transaction_income: 30000,
      mpesa_statement: 'fa7c9b1e-40e3-4698-a9a8-aa5db2c96d05.jpg',
      paylend_number: '557913',
      premise_front_image:
        'https://paylendafrica.s3.fr-par.scw.cloud/61f17612-f047-42f8-8596-b35a2628464f.jpg',
      premise_name: 'Daniel posho mill',
      premise_type: 'shop',
      is_manager: true,
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: true,
      verifying_agent: {
        _id: '630c6b19ec1a4a20265a1942',
        firstname: 'Damaris',
        lastname: 'Muithya',
        phone: '254706507970',
        email: 'nthenyad496@gmail.com',
        status: 'Active',
        identification: '24549439',
        dob: 'Tue Apr 15 1986 09:52:31 GMT+0000 (Coordinated Universal Time)',
        occupation: 'Accountant',
        gender: 'Female',
        income: 30000,
        roles: 'agent',
        email_verified: true,
        phone_verified: true,
        ussd_verified: false,
        profile_pic: null,
        language: 'english',
        country: 'Kenya',
        old_user_id: 9787,
        user_number: 'PLUN960102',
        referral_id: null,
        geolocation: null,
        join_date: '2022-04-07T06:52:35.000Z',
        description: null,
        old_user: true,
        nationality: null,
        identification_picture_front: null,
        identification_picture_back: null,
        marital_status: null,
        employment_status: null,
        residence: null,
        referral_count: 19,
        has_migrated_data: true,
        created_at: '2022-08-29T07:30:33.003Z',
        updated_at: '2023-01-16T06:30:46.008Z',
        __v: 0,
        password: '$2b$10$8w1G3sF75y2X2aJRsDRm8OmX60ozlPh2h4Ri0EFMZAkBAYzky3Nem',
        wallet_updated: true
      },
      created_at: '2023-03-16T12:26:38.641Z',
      updated_at: '2023-03-16T12:27:22.903Z'
    },
    {
      id: '641306ec8295c8e5762172b9',
      user_id: '641306058295c8e576217159',
      business_permit:
        'https://paylendafrica.s3.fr-par.scw.cloud/4cc0ad97-0166-49d5-bcc9-ef0bd752166b.jpg',
      location: {
        type: 'Point',
        coordinates: [36.8815479, -1.2922481]
      },
      premise_address: 'Buruburu, Nairobi, Kenya',
      manager_firstname: 'Robert',
      manager_lastname: 'Kituku',
      manager_identification: 'bf580fb5-995f-4f05-a97b-1e457fa810e6.jpg',
      manager_phone_number: '254724573916',
      manager_email: 'robertkituku14@gmail.com',
      monthly_transaction_income: 40000,
      mpesa_statement: '3856f8b0-bb44-46d8-945b-86a3139fad46.jpg',
      paylend_number: '616418',
      premise_front_image:
        'https://paylendafrica.s3.fr-par.scw.cloud/ba175b2f-4c21-49c2-8678-4ec4b12e4404.jpg',
      premise_name: 'Robert butchery',
      premise_type: 'shop',
      is_manager: true,
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: true,
      verifying_agent: {
        _id: '630c6b19ec1a4a20265a1942',
        firstname: 'Damaris',
        lastname: 'Muithya',
        phone: '254706507970',
        email: 'nthenyad496@gmail.com',
        status: 'Active',
        identification: '24549439',
        dob: 'Tue Apr 15 1986 09:52:31 GMT+0000 (Coordinated Universal Time)',
        occupation: 'Accountant',
        gender: 'Female',
        income: 30000,
        roles: 'agent',
        email_verified: true,
        phone_verified: true,
        ussd_verified: false,
        profile_pic: null,
        language: 'english',
        country: 'Kenya',
        old_user_id: 9787,
        user_number: 'PLUN960102',
        referral_id: null,
        geolocation: null,
        join_date: '2022-04-07T06:52:35.000Z',
        description: null,
        old_user: true,
        nationality: null,
        identification_picture_front: null,
        identification_picture_back: null,
        marital_status: null,
        employment_status: null,
        residence: null,
        referral_count: 19,
        has_migrated_data: true,
        created_at: '2022-08-29T07:30:33.003Z',
        updated_at: '2023-01-16T06:30:46.008Z',
        __v: 0,
        password: '$2b$10$8w1G3sF75y2X2aJRsDRm8OmX60ozlPh2h4Ri0EFMZAkBAYzky3Nem',
        wallet_updated: true
      },
      created_at: '2023-03-16T12:09:16.409Z',
      updated_at: '2023-03-16T12:10:32.188Z'
    },
    {
      id: '641303ad8295c8e576216c7b',
      user_id: '641300c18295c8e57621656b',
      business_permit:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/97495e9f-63b1-45f7-a452-5d4e9f4e1eab.jpg',
      location: {
        type: 'Point',
        coordinates: [-1.2920659, 36.8219462]
      },
      premise_address: 'Nairobi, Kenya',
      manager_firstname: 'Pauline',
      manager_lastname: 'Wangari',
      manager_identification: '24187145',
      manager_phone_number: '254728571451',
      manager_email: 'paulinekanja57@gmail.com',
      monthly_transaction_income: 30000,
      mpesa_statement:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/407feeb5-74c0-4e6d-ae2d-27a1d2d14f88.jpg',
      paylend_number: '558970',
      premise_front_image:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/3d4c8699-0975-4438-90a1-b081ebee53cc.jpg',
      premise_name: 'Pauline sweaters',
      premise_type: 'shop',
      is_manager: true,
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: true,
      verifying_agent: {
        _id: '630c6bf0ec1a4a20265a1c20',
        firstname: 'Eric',
        lastname: 'Gitonga',
        phone: '254722615937',
        email: 'christianerics7@gmail.com',
        status: 'Active',
        identification: '24706379',
        dob: 'Thu Feb 07 1985 13:52:30 GMT+0000 (Coordinated Universal Time)',
        occupation: 'Realtor',
        gender: 'Male',
        income: 70000,
        roles: 'agent',
        email_verified: false,
        phone_verified: true,
        ussd_verified: false,
        profile_pic: null,
        language: 'english',
        country: 'Kenya',
        old_user_id: 9786,
        user_number: 'PLUN764295',
        referral_id: null,
        geolocation: null,
        join_date: '2022-04-07T06:48:57.000Z',
        description: null,
        old_user: true,
        nationality: null,
        identification_picture_front: null,
        identification_picture_back: null,
        marital_status: null,
        employment_status: null,
        residence: null,
        referral_count: 16,
        has_migrated_data: true,
        created_at: '2022-08-29T07:34:08.156Z',
        updated_at: '2023-03-16T13:31:41.749Z',
        __v: 0,
        password: '$2b$10$AafCrLZohru5kpA6zH661.lkCU/Px8mq.VmxXTfCo.zSR9QMtuNGS',
        wallet_updated: true
      },
      created_at: '2023-03-16T11:55:25.578Z',
      updated_at: '2023-03-16T12:02:57.860Z'
    },
    {
      id: '6412f0878295c8e57621583e',
      user_id: '6412ef3c8295c8e5762156ca',
      business_permit:
        'https://paylendafrica.s3.fr-par.scw.cloud/f1aae57f-c0f9-4901-aaf5-40701c54120d.jpg',
      location: {
        type: 'Point',
        coordinates: [36.8815479, -1.2922481]
      },
      premise_address: 'Buruburu, Nairobi, Kenya',
      manager_firstname: 'Michelle',
      manager_lastname: 'Watare',
      manager_identification: 'fad2a2e6-12e2-437a-9093-208aae12200a.jpg',
      manager_phone_number: '254721292345',
      manager_email: 'wataremichelle25@gmail.com',
      monthly_transaction_income: 50000,
      mpesa_statement: '7d1ee9b4-f8dd-4eaa-a51e-2e9ac1f7473d.jpg',
      paylend_number: '249814',
      premise_front_image:
        'https://paylendafrica.s3.fr-par.scw.cloud/c72bdf8d-f329-4a12-8b8a-f0b8007060e5.jpg',
      premise_name: 'Michelle hotel',
      premise_type: 'shop',
      is_manager: true,
      premise_phone: '',
      institutional_level: 'Not Applicable',
      is_premise_verified: true,
      verifying_agent: {
        _id: '630c6b19ec1a4a20265a1942',
        firstname: 'Damaris',
        lastname: 'Muithya',
        phone: '254706507970',
        email: 'nthenyad496@gmail.com',
        status: 'Active',
        identification: '24549439',
        dob: 'Tue Apr 15 1986 09:52:31 GMT+0000 (Coordinated Universal Time)',
        occupation: 'Accountant',
        gender: 'Female',
        income: 30000,
        roles: 'agent',
        email_verified: true,
        phone_verified: true,
        ussd_verified: false,
        profile_pic: null,
        language: 'english',
        country: 'Kenya',
        old_user_id: 9787,
        user_number: 'PLUN960102',
        referral_id: null,
        geolocation: null,
        join_date: '2022-04-07T06:52:35.000Z',
        description: null,
        old_user: true,
        nationality: null,
        identification_picture_front: null,
        identification_picture_back: null,
        marital_status: null,
        employment_status: null,
        residence: null,
        referral_count: 19,
        has_migrated_data: true,
        created_at: '2022-08-29T07:30:33.003Z',
        updated_at: '2023-01-16T06:30:46.008Z',
        __v: 0,
        password: '$2b$10$8w1G3sF75y2X2aJRsDRm8OmX60ozlPh2h4Ri0EFMZAkBAYzky3Nem',
        wallet_updated: true
      },
      created_at: '2023-03-16T10:33:43.209Z',
      updated_at: '2023-03-16T10:34:45.093Z'
    }
  ],
  metadata: {
    count: 881
  }
})

export const transactions = () => ({
  data: [
    {
      id: '64142e9daa115fe4873ec97b',
      user_id: '63e39e08c62e1cdfbba51c17',
      premise_id: '63e39ef1c62e1cdfbba51efd',
      premise_name: 'Nunga best health care',
      paylend_number: '876382',
      premises_owner_id: '63e39e08c62e1cdfbba51c17',
      action_type: 'withdraw_premise_wallet',
      reference_occasion: 'premises_transactions',
      result_code: 0,
      result_desc: 'Withdrawing money from Nunga best health care premise wallet',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 4995.5,
      principal_amount: 4850,
      receipt_number: '1679044253508',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-17T09:10:53.565Z',
      updated_at: '2023-03-17T09:10:53.565Z'
    },
    {
      id: '64142e92aa115fe4873ec95c',
      user_id: '63e39e08c62e1cdfbba51c17',
      premise_id: '63e39ef1c62e1cdfbba51efd',
      premise_name: 'Nunga best health care',
      paylend_number: '876382',
      premises_owner_id: '63e39e08c62e1cdfbba51c17',
      action_type: 'withdraw_premise_wallet',
      reference_occasion: 'premises_transactions',
      result_code: 0,
      result_desc: 'Withdrawing money from Nunga best health care premise wallet',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 4995.5,
      principal_amount: 4850,
      receipt_number: '1679044242515',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-17T09:10:42.609Z',
      updated_at: '2023-03-17T09:10:42.609Z'
    },
    {
      id: '6414283faa115fe4873ec7a0',
      user_id: '630e066eec1a4a20265a9fb3',
      user_name: 'ELIUTHERIUS JUMA WANYONYI',
      user_phone: '254706911460',
      contributors_id: '630e066eec1a4a20265a9fb3',
      action_type: 'contribute_intitiative',
      reference_occasion_id: '6357e4edf13b3aa89aaf1983',
      reference_occasion: 'changisha',
      result_code: 0,
      result_desc: 'Contributing to LA FAMILIA an initiative',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 5,
      principal_amount: 0,
      receipt_number: '1057179975',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-17T08:43:43.027Z',
      updated_at: '2023-03-17T08:43:43.027Z'
    },
    {
      id: '641421efaa115fe4873ec55a',
      user_id: '641315938295c8e576217b26',
      user_name: 'Faith Achola',
      user_phone: '254111751760',
      premise_id: '6413194a8295c8e576217dc9',
      premise_name: 'Faith Shop',
      paylend_number: '802853',
      premises_owner_id: '641315938295c8e576217b26',
      action_type: 'give_loan_to_premise',
      reference_occasion_id: '64131c068295c8e576217fc1',
      reference_occasion: 'inua_biashara',
      result_code: 0,
      result_desc: 'Giving inua_biashara loan to premise',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 2000,
      principal_amount: 0,
      receipt_number: '1678973958237',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-17T08:16:47.802Z',
      updated_at: '2023-03-17T08:16:47.802Z'
    },
    {
      id: '64142110aa115fe4873ec4e6',
      user_id: '6409bd7c2efcb08e63c67823',
      user_name: 'John Waweru',
      user_phone: '254710563491',
      premise_id: '6409bf332efcb08e63c67a67',
      premise_name: 'John designs',
      paylend_number: '689521',
      premises_owner_id: '6409bd7c2efcb08e63c67823',
      action_type: 'give_loan_to_premise',
      reference_occasion_id: '6413e7d48295c8e57621b152',
      reference_occasion: 'inua_biashara',
      result_code: 0,
      result_desc: 'Giving inua_biashara loan to premise',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 2000,
      principal_amount: 0,
      receipt_number: '1679026132510',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-17T08:13:04.119Z',
      updated_at: '2023-03-17T08:13:04.119Z'
    },
    {
      id: '64141f85aa115fe4873ec49f',
      user_id: '6411b9eb8295c8e57620d5b9',
      user_name: 'EUNICE WANJIRU',
      user_phone: '254704958028',
      premise_id: '6411bc4c8295c8e57620d903',
      premise_name: 'EUNICE Fashion shop',
      paylend_number: '169820',
      premises_owner_id: '6411b9eb8295c8e57620d5b9',
      action_type: 'give_loan_to_premise',
      reference_occasion_id: '6411bdd48295c8e57620dbf5',
      reference_occasion: 'inua_biashara',
      result_code: 0,
      result_desc: 'Giving inua_biashara loan to premise',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 2000,
      principal_amount: 0,
      receipt_number: '1678884308935',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-17T08:06:29.055Z',
      updated_at: '2023-03-17T08:06:29.055Z'
    },
    {
      id: '64141372aa115fe4873ec2cb',
      user_id: '63e39e08c62e1cdfbba51c17',
      user_name: 'Daniel Mutune',
      user_phone: '254721350257',
      premise_id: '63e39ef1c62e1cdfbba51efd',
      premise_name: 'Nunga best health care',
      paylend_number: '876382',
      premises_owner_id: '63e39e08c62e1cdfbba51c17',
      action_type: 'give_loan_to_premise',
      reference_occasion_id: '641302478295c8e5762169a8',
      reference_occasion: 'inua_biashara',
      result_code: 0,
      result_desc: 'Giving inua_biashara loan to premise',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 5000,
      principal_amount: 0,
      receipt_number: '1678967367319',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-17T07:14:58.532Z',
      updated_at: '2023-03-17T07:14:58.532Z'
    },
    {
      id: '641412f3aa115fe4873ec2c4',
      user_id: '6328883501936785ec128723',
      premise_id: '6329cbc45da5b13fe2e7d190',
      premise_name: 'Shilos book shop',
      paylend_number: '798724',
      premises_owner_id: '6328883501936785ec128723',
      action_type: 'withdraw_premise_wallet',
      reference_occasion: 'premises_transactions',
      result_code: 0,
      result_desc: 'Withdrawing money from Shilos book shop premise wallet',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 15038,
      principal_amount: 14600,
      receipt_number: '1679037171028',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-17T07:12:51.084Z',
      updated_at: '2023-03-17T07:12:51.084Z'
    },
    {
      id: '64141248aa115fe4873ec258',
      user_id: '6328883501936785ec128723',
      user_name: 'Faith Njeru',
      user_phone: '254748587018',
      premise_id: '6329cbc45da5b13fe2e7d190',
      premise_name: 'Shilos book shop',
      paylend_number: '798724',
      premises_owner_id: '6328883501936785ec128723',
      action_type: 'give_loan_to_premise',
      reference_occasion_id: '6412ee078295c8e576215628',
      reference_occasion: 'inua_biashara',
      result_code: 0,
      result_desc: 'Giving inua_biashara loan to premise',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 15000,
      principal_amount: 0,
      receipt_number: '1678962183764',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-17T07:10:00.509Z',
      updated_at: '2023-03-17T07:10:00.509Z'
    },
    {
      id: '64132013aa115fe4873eb2e4',
      user_id: '63ce6ef4ac69917a5058e79c',
      user_name: 'Geoffrey Kimani',
      user_phone: '254702111858',
      premise_id: '63ce7b9753af9a93ec03f4d8',
      premise_name: 'Kingston leather ',
      paylend_number: '520852',
      premises_owner_id: '63ce6ef4ac69917a5058e79c',
      action_type: 'repay_loan_by_premise',
      reference_occasion: 'inua_biashara',
      result_code: 0,
      result_desc: 'Repaying loan',
      callback_metadata: null,
      currency: 'KES',
      country: 'Kenya',
      amount: 100,
      principal_amount: 0,
      receipt_number: '1678974995211',
      phone_number: '',
      business_short_code: 0,
      status: 'successful',
      created_at: '2023-03-16T13:56:35.289Z',
      updated_at: '2023-03-16T13:56:35.289Z'
    }
  ],
  metadata: {
    count: 3665
  }
})

export const withdrawRequests = () => ({
  data: [
    {
      id: '6405a60f061016177a6eb40a',
      user_id: '6304c0743946000e911aad85',
      amount: '50',
      premise_id: '63ef3025080cb7b8a2b200fe',
      premise_wallet_id: '63ef3025580d353cc7caf92b',
      phone: '254718233975',
      status: 'complete',
      created_at: '2023-03-06T08:36:31.358Z',
      updated_at: '2023-03-06T08:36:45.021Z'
    },
    {
      id: '6405b3edafe924af74bc2f1a',
      user_id: '63ce7050591719d4a5eb51ed',
      amount: '6800',
      premise_id: '63cfde93f07307a234bec68b',
      premise_wallet_id: '63cfde935626ec9386b766cf',
      phone: '254722539413',
      status: 'complete',
      created_at: '2023-03-06T09:35:41.442Z',
      updated_at: '2023-03-06T09:35:55.060Z'
    },
    {
      id: '6405bc1aafe924af74bc3535',
      user_id: '63d3a6916ba9fc7e63f52eba',
      amount: '1798',
      premise_id: '63d3b6455e9dc275727c271d',
      premise_owner_id: '63d3a6916ba9fc7e63f52eba',
      premise_wallet_id: '63d3b6466a3e5b12ad79fd7f',
      phone: '254722947341',
      status: 'complete',
      created_at: '2023-03-06T10:10:34.190Z',
      updated_at: '2023-03-06T10:10:36.993Z'
    },
    {
      id: '6405bf99afe924af74bc38b2',
      user_id: '63e38af101f8b208d0faa8dc',
      amount: '1980',
      premise_id: '63e38ddd01f8b208d0faad04',
      premise_owner_id: '63e38af101f8b208d0faa8dc',
      premise_wallet_id: '63e38ddd4f65167b213b48bf',
      phone: '254740492803',
      status: 'complete',
      created_at: '2023-03-06T10:25:29.609Z',
      updated_at: '2023-03-06T10:25:32.366Z'
    },
    {
      id: '6405c3a4afe924af74bc394e',
      user_id: '6304af793946000e911aa9a9',
      amount: '1900',
      premise_id: '6304c06c3946000e911aad6c',
      premise_wallet_id: '6304c06c107e36a04f908c39',
      phone: '254720529868',
      status: 'complete',
      created_at: '2023-03-06T10:42:44.350Z',
      updated_at: '2023-03-06T10:42:47.041Z'
    },
    {
      id: '6405e2485be53bca05d3a3cc',
      user_id: '6358fa1bba7f5ac4a8459b51',
      amount: '4800',
      premise_id: '6358fa28ba7f5ac4a8459b86',
      premise_owner_id: '6358fa1bba7f5ac4a8459b51',
      premise_wallet_id: '6358fa2864bd3bf22ddfc9a9',
      phone: '254720355758',
      status: 'complete',
      created_at: '2023-03-06T12:53:28.740Z',
      updated_at: '2023-03-06T12:53:42.443Z'
    },
    {
      id: '6405ef535be53bca05d3aa4a',
      user_id: '6317282ce089516a61684d5b',
      amount: '4855',
      premise_id: '63172e0de089516a61685b66',
      premise_owner_id: '6317282ce089516a61684d5b',
      premise_wallet_id: '63172e0d75012cf8657c59da',
      phone: '254793557669',
      status: 'complete',
      created_at: '2023-03-06T13:49:07.859Z',
      updated_at: '2023-03-06T13:49:23.701Z'
    },
    {
      id: '6405f6885be53bca05d3ac2b',
      user_id: '63f8ef9ea7b515e0badcab77',
      amount: '1850',
      premise_id: '63f8f7b9a0e04457c5eadf6c',
      premise_owner_id: '63f8ef9ea7b515e0badcab77',
      premise_wallet_id: '63f8f7b95ad9ade71cbccbab',
      phone: '254748233220',
      status: 'complete',
      created_at: '2023-03-06T14:19:52.683Z',
      updated_at: '2023-03-06T14:19:55.180Z'
    },
    {
      id: '6405f7345be53bca05d3ac7f',
      user_id: '63f8ef9ea7b515e0badcab77',
      amount: '90',
      premise_id: '63f8f7b9a0e04457c5eadf6c',
      premise_owner_id: '63f8ef9ea7b515e0badcab77',
      premise_wallet_id: '63f8f7b95ad9ade71cbccbab',
      phone: '254748233220',
      status: 'complete',
      created_at: '2023-03-06T14:22:44.059Z',
      updated_at: '2023-03-06T14:22:46.652Z'
    },
    {
      id: '6406000b5be53bca05d3af79',
      user_id: '6336a71f3fedd1e6063769ef',
      amount: '11000',
      premise_id: '6336a7303fedd1e606376a0a',
      premise_owner_id: '6336a71f3fedd1e6063769ef',
      premise_wallet_id: '6336a7302be1745c641957a9',
      phone: '254721406287',
      status: 'complete',
      created_at: '2023-03-06T15:00:27.640Z',
      updated_at: '2023-03-06T15:00:41.336Z'
    },
    {
      id: '64067bb55be53bca05d3cb49',
      user_id: '63f8e0337027e9fb990180d2',
      amount: '1940',
      premise_id: '63f8e4e67027e9fb990182a2',
      premise_owner_id: '63f8e0337027e9fb990180d2',
      premise_wallet_id: '63f8e4e6d26f649188d3b07f',
      phone: '254796428285',
      status: 'complete',
      created_at: '2023-03-06T23:48:05.324Z',
      updated_at: '2023-03-06T23:48:18.989Z'
    },
    {
      id: '6406c5625be53bca05d3cdda',
      user_id: '63e36f4bfd558a853c918fa6',
      amount: '5200',
      premise_id: '63e37dbf5eef7c088b9c5240',
      premise_owner_id: '63e36f4bfd558a853c918fa6',
      premise_wallet_id: '63e37dbfc8777ba97c25ae96',
      phone: '254724312941',
      status: 'complete',
      created_at: '2023-03-07T05:02:26.447Z',
      updated_at: '2023-03-07T05:02:28.970Z'
    }
  ],
  metadata: {
    count: 55
  }
})

export const initiatives = () => ({
  data: [
    {
      id: '6410b5871a635e6c346dc2cc',
      name: 'Gilbert choga yaa',
      total_raised: 0,
      target_amount: 10300,
      end_date: '2023-03-14T17:56:41.127Z',
      visibility: 'private',
      category: 'Educational',
      location: '',
      initiative_type: 'individual',
      withdraw_facility: '',
      initiative_number: 2981993,
      withdraw_account: '',
      support_document: '59a9422a-cf0b-4c51-a98e-991f2e4465a8.jpg',
      short_summary: 'i want to pay school fees for my daughter.',
      members: [
        {
          members_id: '64106bba1a635e6c346d8523',
          members_name: 'Gilbert Yaa',
          phone: '254790705080'
        }
      ],
      user_id: '64106bba1a635e6c346d8523',
      contact: '0790705080',
      status: 'A',
      created_at: '2023-03-14T17:57:27.097Z',
      updated_at: '2023-03-14T17:57:27.118Z'
    },
    {
      id: '640a26862efcb08e63c6c45d',
      name: 'Helping student with no fees',
      total_raised: 0,
      target_amount: 36000,
      end_date: '2023-03-16T18:31:14.999Z',
      visibility: 'public',
      category: 'Educational',
      location: '',
      initiative_type: 'individual',
      withdraw_facility: '',
      initiative_number: 2315830,
      withdraw_account: '',
      support_document: '5c229fdc-701d-48a9-8be5-165c9f9fb2bc.jpg',
      short_summary: 'may you please assist me in paying my school fees',
      members: [
        {
          members_id: '6408ba7f5be53bca05d4ccb5',
          members_name: 'Meshac Ochieng',
          phone: '254768291058'
        },
        {
          members_id: '6408ba7f5be53bca05d4ccb5',
          members_name: 'Meshac Ochieng',
          phone: '254768291058'
        }
      ],
      user_id: '6408ba7f5be53bca05d4ccb5',
      contact: '0768291058',
      status: 'A',
      created_at: '2023-03-09T18:33:42.354Z',
      updated_at: '2023-03-09T18:36:34.547Z'
    },
    {
      id: '6409dc8b2efcb08e63c69a07',
      name: 'Help a girl and boy child initiative',
      total_raised: 0,
      target_amount: 10000,
      end_date: '2023-04-22T13:15:59.253Z',
      visibility: 'public',
      category: 'Other',
      location: '',
      initiative_type: 'individual',
      withdraw_facility: '',
      initiative_number: 2031862,
      withdraw_account: '',
      support_document: '98a4af39-e659-4a46-b25d-6c1c930a6ed1.jpg',
      short_summary: 'touching and giving back to the community.',
      members: [
        {
          members_id: '6409d44f2efcb08e63c693d4',
          members_name: 'Alice Ndugutu',
          phone: '254720006044'
        }
      ],
      user_id: '6409d44f2efcb08e63c693d4',
      contact: '0705554477',
      status: 'A',
      created_at: '2023-03-09T13:18:03.976Z',
      updated_at: '2023-03-09T13:18:03.998Z'
    },
    {
      id: '6406f5c35be53bca05d3d965',
      name: 'Feed a Child Arid initiative by Yatta Green investments',
      total_raised: 0,
      target_amount: 300000,
      end_date: '2023-03-30T08:24:45.509Z',
      visibility: 'public',
      category: 'Other',
      location: '',
      initiative_type: 'individual',
      withdraw_facility: '',
      initiative_number: 2102451,
      withdraw_account: '',
      support_document: 'null',
      short_summary:
        'we are raising funds to feed vulnerable families in yatta and masinga kindly support this noble call as we help them put something on the table at such a time.',
      members: [
        {
          members_id: '63ed4b1b3fad39116126fad3',
          members_name: 'Joseph Kyalo',
          phone: '254110577174'
        }
      ],
      user_id: '63ed4b1b3fad39116126fad3',
      contact: '0101228361',
      status: 'A',
      created_at: '2023-03-07T08:28:51.380Z',
      updated_at: '2023-03-07T08:28:51.403Z'
    },
    {
      id: '640592bc589ca148d086d821',
      name: 'adnan enterprise',
      total_raised: 10,
      target_amount: 30000,
      end_date: '2023-04-30T07:12:48.329Z',
      visibility: 'public',
      category: 'Other',
      location: '',
      initiative_type: 'individual',
      withdraw_facility: '',
      initiative_number: 2101691,
      withdraw_account: '',
      support_document: 'null',
      short_summary: 'invest with adnan enterprise 0.2 every month guaranteed',
      members: [
        {
          members_id: '63b933bcbf5e3483cb772556',
          members_name: 'Allan Kamakia',
          phone: '254726441901'
        },
        {
          members_id: '6408ba7f5be53bca05d4ccb5',
          members_name: 'Meshac Ochieng',
          phone: '254768291058'
        }
      ],
      user_id: '63b933bcbf5e3483cb772556',
      contact: '0700280022',
      status: 'A',
      created_at: '2023-03-06T07:14:04.362Z',
      updated_at: '2023-03-09T04:54:48.993Z'
    },
    {
      id: '6400c954fed3889dab15064c',
      name: 'Burial for my sister',
      total_raised: 0,
      target_amount: 100000,
      end_date: '2023-03-20T16:00:26.355Z',
      visibility: 'public',
      category: 'Funeral',
      location: '',
      initiative_type: 'individual',
      withdraw_facility: '',
      initiative_number: 2767844,
      withdraw_account: '',
      support_document: '10117ddf-4d94-4717-b1b9-9ddb07ca9aa1.jpg',
      short_summary:
        'my sister we did love you but god loved you the most. rip till we meet again',
      members: [
        {
          members_id: '6400bc36fed3889dab1500a3',
          members_name: 'Lawrence Njagi',
          phone: '254720979401'
        },
        {
          members_id: '6400bc36fed3889dab1500a3',
          members_name: 'Lawrence Njagi',
          phone: '254720979401'
        }
      ],
      user_id: '6400bc36fed3889dab1500a3',
      contact: '0720979401',
      status: 'A',
      created_at: '2023-03-02T16:05:40.646Z',
      updated_at: '2023-03-02T16:06:30.422Z'
    },
    {
      id: '63fdac08cba0337a8b843184',
      name: 'Business loan',
      total_raised: 0,
      target_amount: 10000,
      end_date: '2023-03-07T07:22:09.797Z',
      visibility: 'private',
      category: 'Other',
      location: '',
      initiative_type: 'individual',
      withdraw_facility: '',
      initiative_number: 2820817,
      withdraw_account: '',
      support_document: 'b5b4132c-92c6-40c4-b376-e8b2c07bab10.jpg',
      short_summary: 'i need a loan for boosting the stock',
      members: [
        {
          members_id: '63ec88592bd29edd260ec6a3',
          members_name: 'Mutugi Murimi',
          phone: '254758059907'
        }
      ],
      user_id: '63ec88592bd29edd260ec6a3',
      contact: '0758059907',
      status: 'A',
      created_at: '2023-02-28T07:23:52.799Z',
      updated_at: '2023-02-28T07:23:52.842Z'
    },
    {
      id: '63fdac00cba0337a8b84317a',
      name: 'Business loan',
      total_raised: 0,
      target_amount: 10000,
      end_date: '2023-03-07T07:22:09.797Z',
      visibility: 'private',
      category: 'Other',
      location: '',
      initiative_type: 'individual',
      withdraw_facility: '',
      initiative_number: 2170922,
      withdraw_account: '',
      support_document: 'b5b4132c-92c6-40c4-b376-e8b2c07bab10.jpg',
      short_summary: 'i need a loan for boosting the stock',
      members: [
        {
          members_id: '63ec88592bd29edd260ec6a3',
          members_name: 'Mutugi Murimi',
          phone: '254758059907'
        }
      ],
      user_id: '63ec88592bd29edd260ec6a3',
      contact: '0758059907',
      status: 'A',
      created_at: '2023-02-28T07:23:44.565Z',
      updated_at: '2023-02-28T07:23:44.622Z'
    },
    {
      id: '63fdabf8cba0337a8b843170',
      name: 'Business loan',
      total_raised: 0,
      target_amount: 10000,
      end_date: '2023-03-07T07:22:09.797Z',
      visibility: 'private',
      category: 'Other',
      location: '',
      initiative_type: 'individual',
      withdraw_facility: '',
      initiative_number: 2568906,
      withdraw_account: '',
      support_document: 'b5b4132c-92c6-40c4-b376-e8b2c07bab10.jpg',
      short_summary: 'i need a loan for boosting the stock',
      members: [
        {
          members_id: '63ec88592bd29edd260ec6a3',
          members_name: 'Mutugi Murimi',
          phone: '254758059907'
        }
      ],
      user_id: '63ec88592bd29edd260ec6a3',
      contact: '0758059907',
      status: 'A',
      created_at: '2023-02-28T07:23:36.707Z',
      updated_at: '2023-02-28T07:23:36.821Z'
    },
    {
      id: '63fab4f8aa55b152bfaba546',
      name: 'Flight fee for Desmon Kipchirchir who is going for further studies abroad (Australia)',
      total_raised: 0,
      target_amount: 500000,
      end_date: '2023-04-30',
      visibility: 'public',
      category: 'Education',
      location: '',
      initiative_type: 'organization',
      withdraw_facility: '',
      initiative_number: 2136305,
      withdraw_account: '',
      support_document:
        'https://paylend-v2-imagebucket-1.s3.eu-central-1.amazonaws.com/caddd96e-3cd5-4d64-87e8-1069640f5f78.jpeg',
      short_summary: 'Help me fund the flight guys your hand will be appreciated',
      members: [
        {
          members_id: '63f9b4f61590d2c388404fef',
          members_name: 'Desmon Kipchirchir',
          phone: '254740189159'
        }
      ],
      user_id: '63f9b4f61590d2c388404fef',
      contact: '254740189159',
      status: 'A',
      created_at: '2023-02-26T01:25:12.857Z',
      updated_at: '2023-02-26T01:25:12.875Z'
    }
  ],
  metadata: {
    count: 61
  }
})

export const globalConfigs = () => ({
  data: [
    {
      id: '63275ef5c01744fe855d225f',
      config_name: 'changisha_creation_on',
      value: 'true',
      description: 'Set changisha initiative creation on / off',
      created_at: '2022-09-18T18:09:57.321Z',
      updated_at: '2022-09-18T18:09:57.321Z'
    },
    {
      id: '632730d78a859e88b61f12cd',
      config_name: 'max_no_of_premises',
      value: '1',
      description: 'maximum number of premises per user',
      created_at: '2022-09-18T14:53:11.272Z',
      updated_at: '2022-09-18T14:53:11.272Z'
    },
    {
      id: '63135282992f5274288b0b31',
      config_name: 'premise_wallet_withdrawal_charge',
      value: '3',
      description: 'withdrawal charge % to be applied on premise wallets',
      created_at: '2022-09-03T13:11:30.522Z',
      updated_at: '2022-09-03T13:11:30.522Z'
    },
    {
      id: '6304b0fb3946000e911aaa6e',
      config_name: 'is_withdrawal_on',
      value: 'true',
      description: 'Is withdrawal on',
      created_at: '2022-08-23T10:50:35.799Z',
      updated_at: '2022-09-06T07:29:32.386Z'
    },
    {
      id: '6304b0da3946000e911aaa6a',
      config_name: 'agent_premise_verification_on',
      value: 'true',
      description: 'Ability for agent to verify a premise',
      created_at: '2022-08-23T10:50:02.937Z',
      updated_at: '2022-08-23T10:55:31.824Z'
    },
    {
      id: '6304b0b63946000e911aaa66',
      config_name: 'inua_biashara_on',
      value: 'true',
      description: 'Ability for premises to apply for loans',
      created_at: '2022-08-23T10:49:26.494Z',
      updated_at: '2022-09-01T17:35:52.412Z'
    },
    {
      id: '6304b0543946000e911aaa59',
      config_name: 'premise_registration_on',
      value: 'true',
      description: 'Ability for customers to register a premise/school',
      created_at: '2022-08-23T10:47:48.706Z',
      updated_at: '2022-08-23T10:48:29.592Z'
    },
    {
      id: '6304afd93946000e911aaa55',
      config_name: 'okoa_afya_on',
      value: 'false',
      description: 'Ability for customers to take loans on health',
      created_at: '2022-08-23T10:45:45.978Z',
      updated_at: '2022-08-23T10:59:04.425Z'
    },
    {
      id: '6304afab3946000e911aaa09',
      config_name: 'okoa_bills_on',
      value: 'false',
      description: 'Ability for users to take okoa bills loans',
      created_at: '2022-08-23T10:44:59.294Z',
      updated_at: '2022-08-23T10:59:26.837Z'
    },
    {
      id: '6304af7b3946000e911aa9b4',
      config_name: 'okoa_kilimo_on',
      value: 'false',
      description: 'New description',
      created_at: '2022-08-23T10:44:11.765Z',
      updated_at: '2022-09-16T05:55:39.805Z'
    }
  ],
  metadata: {
    count: 15
  }
})
