import { configureStore } from '@reduxjs/toolkit'
import dashboard from './containers/DashboardLayout/store'
import auth from './containers/Auth/store'

export const store = configureStore({
  reducer: {
    dashboard,
    auth
  }
})
