const countries = [
  {
    name: 'Kenya',
    phone_code: '254',
    language: 'en',
    code: 'ke',
    currency: 'Ksh'
  }
]

export default countries
