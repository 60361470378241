import PropTypes from 'prop-types'

function Table({ header, body, pagination }) {
  return (
    <div className=''>
      <div className='-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
          <div className='overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg'>
            <table className='min-w-full divide-y divide-gray-300'>
              <thead className='bg-gray-50'>{header}</thead>
              <tbody className='divide-y divide-gray-200 bg-white'>{body}</tbody>
            </table>
            {pagination && pagination}
          </div>
        </div>
      </div>
    </div>
  )
}

Table.propTypes = {
  header: PropTypes.node.isRequired,
  body: PropTypes.node.isRequired,
  pagination: PropTypes.node
}

export default Table
