import PropTypes from 'prop-types'
import ReactPhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import countries from '../../helpers/countries.js'

function PhoneInput({
  country,
  placeholder,
  value,
  onChange,
  name,
  hasError,
  errorMessage,
  disabled,
  inputStyle,
  inputRef
}) {
  return (
    <>
      <ReactPhoneInput
        country={country}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onlyCountries={countries.map((country) => country.code)}
        countryCodeEditable={false}
        disabled={disabled}
        inputClass='bg-gray-300'
        inputProps={{
          name,
          ref: inputRef
        }}
        inputStyle={inputStyle}
      />
      <span className='text-xs font-gotham-rounded-book block mt-1 text-red-600'>
        {errorMessage}
      </span>
    </>
  )
}

PhoneInput.defaultProps = {
  country: countries.find((country) => country.code === 'ke').code
}

PhoneInput.propTypes = {
  country: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
  inputStyle: PropTypes.object,
  inputRef: PropTypes.any
}

export default PhoneInput
