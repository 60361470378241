import DashboardLayout from '../DashboardLayout'
import { Helmet } from 'react-helmet'

function Analytics() {
  return (
    <>
      <Helmet>
        <title>Reports and Analytics</title>
      </Helmet>
      <DashboardLayout>
        <div className='mx-auto max-w-6xl'>
          <h2 className='text-lg font-medium leading-6 text-gray-900'>
            Reports and Analytics
          </h2>
        </div>
      </DashboardLayout>
    </>
  )
}

export default Analytics
