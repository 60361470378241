import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './containers/App'
import { store } from './store'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import reportWebVitals from './reportWebVitals'
import './assets/css/index.css'
import './i18n'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>
)

serviceWorkerRegistration.unregister()
reportWebVitals()
