/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { omit } from 'ramda'
import { Menu, Transition } from '@headlessui/react'
import { Bars3CenterLeftIcon } from '@heroicons/react/24/outline'
import { MagnifyingGlassIcon, ChevronDownIcon } from '@heroicons/react/20/solid'
import { PROFILE } from '../../containers/App/RouteConstants'
import { logoutUser, setUser } from '../../containers/Auth/store'
import { icons } from '../../assets/img'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Header({ setSidebarOpen, user }) {
  const dispatch = useDispatch()

  return (
    <div className='flex h-16 flex-shrink-0 sticky shadow top-0 z-10 border-b border-gray-200 bg-white lg:border-none'>
      <button
        type='button'
        className='border-r border-gray-200 px-4 text-gray-400 focus:outline-none focus:ring-0 lg:hidden'
        onClick={() => setSidebarOpen(true)}
      >
        <span className='sr-only'>Open sidebar</span>
        <Bars3CenterLeftIcon className='h-6 w-6' aria-hidden='true' />
      </button>
      {/* Search bar */}
      <div className='flex flex-1 justify-between px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8'>
        <div className='flex flex-1'>
          <form className='flex w-full md:ml-0' action='#' method='GET'>
            <label htmlFor='search-field' className='sr-only'>
              Search
            </label>
            <div className='relative w-full text-gray-400 focus-within:text-gray-600'>
              <div
                className='pointer-events-none absolute inset-y-0 left-0 flex items-center'
                aria-hidden='true'
              >
                <MagnifyingGlassIcon className='h-5 w-5' aria-hidden='true' />
              </div>
              <input
                id='search-field'
                name='search-field'
                className='block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm'
                placeholder='Search transactions'
                type='search'
              />
            </div>
          </form>
        </div>
        <div className='ml-4 flex items-center md:ml-6'>
          {/* Profile dropdown */}
          <Menu as='div' className='relative ml-3'>
            <div>
              <Menu.Button className='flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-0 lg:rounded-md lg:p-2 lg:hover:bg-gray-50'>
                <img
                  className='h-8 w-8 rounded-full'
                  src={user?.avatar || icons.iconElsie}
                  alt=''
                  onError={() => dispatch(setUser(omit(['avatar'], user)))}
                />
                <span className='ml-3 hidden text-sm font-medium text-gray-700 lg:block'>
                  <span className='sr-only'>Open user menu for </span>
                  {user?.name}
                </span>
                <ChevronDownIcon
                  className='ml-1 hidden h-5 w-5 flex-shrink-0 text-gray-400 lg:block'
                  aria-hidden='true'
                />
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter='transition ease-out duration-100'
              enterFrom='transform opacity-0 scale-95'
              enterTo='transform opacity-100 scale-100'
              leave='transition ease-in duration-75'
              leaveFrom='transform opacity-100 scale-100'
              leaveTo='transform opacity-0 scale-95'
            >
              <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      to={PROFILE}
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                    >
                      My Profile
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link
                      className={classNames(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}
                      onClick={(e) => {
                        e.preventDefault()
                        dispatch(logoutUser())
                      }}
                    >
                      Logout
                    </Link>
                  )}
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  setSidebarOpen: PropTypes.func,
  user: PropTypes.object.isRequired
}

export default Header
