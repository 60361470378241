import PropTypes from 'prop-types'

function Button({ label, type, classes, onClick, disabled }) {
  const getButtonType = (buttonType) => {
    let btnClasses = ''
    switch (buttonType) {
      case 'danger':
        btnClasses =
          'bg-red-600 hover:bg-red-700 text-white border border-transparent text-white'
        break
      case 'primary':
        btnClasses =
          'bg-secondary-800 hover:bg-secondary-900 text-white border border-transparent text-white'
        break
      case 'white':
        btnClasses =
          'bg-white border text-body border-secondary-900 w-full transition ease-in-out duration-200 focus:outline-none'
        break
      default:
        break
    }

    return btnClasses
  }

  return (
    <button
      className={`${getButtonType(
        type
      )} rounded w-full transition ease-in-out duration-200 focus:outline-none ${' '} ${classes} flex justify-center rounded-md border border-transparent py-2 px-4 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 ${
        disabled ? 'opacity-50 pointer-events-none' : ''
      }`}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

Button.defaultProps = {
  type: 'primary'
}

Button.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  classes: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default Button
