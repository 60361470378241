/* eslint-disable jsx-a11y/anchor-is-valid */
import { Helmet } from 'react-helmet'
import DashboardLayout from '../DashboardLayout'
import {
  BanknotesIcon,
  ArrowDownTrayIcon,
  ShieldExclamationIcon,
  ArrowUpTrayIcon
} from '@heroicons/react/20/solid'
import { Button, Table } from '../../components'
import { transactions as data } from '../../data'
import { formatToHumanDate } from '../../helpers/utils'

const stats = [
  { name: 'Total Transactions', stat: '6200', icon: BanknotesIcon },
  { name: 'Withdrawals', stat: '886', icon: ArrowDownTrayIcon },
  { name: 'Fines', stat: '2690', icon: ShieldExclamationIcon },
  { name: 'Repayments', stat: '120', icon: ArrowUpTrayIcon }
]

function Transactions() {
  const transactions = data().data
  return (
    <>
      <Helmet>
        <title>Transactions</title>
      </Helmet>
      <DashboardLayout>
        <div className='sm:flex sm:items-center'>
          <div className='sm:flex-auto'>
            <h1 className='text-base font-semibold leading-6 text-gray-900'>
              Transactions
            </h1>
            <p className='mt-2 text-sm text-gray-700'>A list of all transactions</p>
          </div>
        </div>
        <div className=''>
          <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4'>
            {stats.map((item) => (
              <div key={item.name} className='overflow-hidden rounded-lg bg-white shadow'>
                <div className='p-5'>
                  <div className='flex items-center'>
                    <div className='flex-shrink-0'>
                      <item.icon className='h-6 w-6 text-gray-400' aria-hidden='true' />
                    </div>
                    <div className='ml-5 w-0 flex-1'>
                      <dl>
                        <dt className='truncate text-sm font-medium text-gray-500'>
                          {item.name}
                        </dt>
                        <dd>
                          <div className='text-lg font-medium text-gray-900'>
                            {item.stat}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </dl>
        </div>
        <div className='mt-8 flow-root'>
          <Table
            header={
              <tr>
                <th
                  scope='col'
                  className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'
                >
                  Receipt Number
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Reference Occasion
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Type
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Amount
                </th>
                <th
                  scope='col'
                  className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'
                >
                  Date Created
                </th>
                <th scope='col' className='relative py-3.5 pl-3 pr-4 sm:pr-6'>
                  <span className='sr-only'>Action</span>
                </th>
              </tr>
            }
            body={transactions.map((transaction) => (
              <tr key={transaction.id}>
                <td className='whitespace-nowrap py-4 pl-4 pr-3 text-sm'>
                  {transaction.receipt_number}
                </td>
                <td className='whitespace-nowrap px-3 py-4 capitalize text-sm text-gray-500'>
                  <div className='text-gray-900'>{transaction.reference_occasion}</div>
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                  {transaction.action_type}
                </td>
                <td className='whitespace-nowrap px-3 py-4 text-sm text-gray-900'>
                  {transaction.amount}
                </td>
                <td className='whitespace-nowrap px-3 capitalize py-4 text-sm text-gray-900'>
                  {formatToHumanDate(transaction.created_at)}
                </td>
                <td className='relative whitespace-nowrap py-4 px-3 text-right text-sm font-medium'>
                  <Button label='View' />
                </td>
              </tr>
            ))}
            pagination={
              <nav
                className='flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6'
                aria-label='Pagination'
              >
                <div className='hidden sm:block'>
                  <p className='text-sm text-gray-700'>
                    Showing <span className='font-medium'>1</span> to{' '}
                    <span className='font-medium'>10</span> of{' '}
                    <span className='font-medium'>6964</span> transactions
                  </p>
                </div>
                <div className='flex flex-1 justify-between sm:justify-end'>
                  <a
                    href='#'
                    className='relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0'
                  >
                    Previous
                  </a>
                  <a
                    href='#'
                    className='relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:outline-offset-0'
                  >
                    Next
                  </a>
                </div>
              </nav>
            }
          />
        </div>
      </DashboardLayout>
    </>
  )
}

export default Transactions
