import { Navigate, Routes, Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import DashboardLayout from '../DashboardLayout'
import {
  ANALYTICS,
  INDEX,
  LOANS,
  OVERVIEW,
  PREMISES,
  PROFILE,
  SYSTEM_SETTINGS,
  TRANSACTIONS,
  USERS,
  VOUCHERS,
  WITHDRAWAL_REQUESTS,
  INITIATIVES
} from './RouteConstants'
import OverviewPage from '../../containers/Home'
import UsersPage from '../../containers/Users'
import LoansPage from '../../containers/Loans'
import PremisesPage from '../../containers/Premises'
import TransactionsPage from '../../containers/Transactions'
import VouchersPage from '../../containers/Vouchers'
import WithdrawRequestsPage from '../../containers/WithdrawRequests'
import AnalyticsPage from '../../containers/Analytics'
import SystemSettingsPage from '../../containers/SystemSettings'
import ProfilePage from '../../containers/Profile'
import InitiativesPage from '../../containers/Initiatives'

const DashboardRoute = ({ element, ...rest }) => (
  <Routes>
    <Route
      {...rest}
      render={(props) => <DashboardLayout {...props}>{element}</DashboardLayout>}
    />
    <Route element={<Navigate replace to={OVERVIEW} />} path={INDEX} />
  </Routes>
)

DashboardRoute.propTypes = {
  element: PropTypes.element
}

export default function DashboardRoutes() {
  return (
    <DashboardRoute>
      <Route element={<OverviewPage />} exact path={OVERVIEW} />
      <Route element={<UsersPage />} exact path={USERS} />
      <Route element={<LoansPage />} exact path={LOANS} />
      <Route element={<PremisesPage />} exact path={PREMISES} />
      <Route element={<TransactionsPage />} exact path={TRANSACTIONS} />
      <Route element={<VouchersPage />} exact path={VOUCHERS} />
      <Route element={<InitiativesPage />} exact path={INITIATIVES} />
      <Route element={<WithdrawRequestsPage />} exact path={WITHDRAWAL_REQUESTS} />
      <Route element={<AnalyticsPage />} exact path={ANALYTICS} />
      <Route element={<SystemSettingsPage />} exact path={SYSTEM_SETTINGS} />
      <Route element={<ProfilePage />} exact path={PROFILE} />
    </DashboardRoute>
  )
}
