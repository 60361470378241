import { Helmet } from 'react-helmet'
import DashboardLayout from '../DashboardLayout'

function Vouchers() {
  return (
    <>
      <Helmet>
        <title>Vouchers</title>
      </Helmet>
      <DashboardLayout>
        <div className='mx-auto max-w-6xl'>
          <h2 className='text-lg font-medium leading-6 text-gray-900'>Vouchers</h2>
        </div>
      </DashboardLayout>
    </>
  )
}

export default Vouchers
