import { isEmpty } from 'ramda'
import { Fragment } from 'react'
import { Navigate } from 'react-router-dom'
import { LOGIN } from './RouteConstants'
import AuthenticatedRoutes from './DashboardRoutes'
import { getUserDetails } from '../../helpers/utils'

export default function AuthAndDashboardRoutes() {
  const isAuthenticated = !isEmpty(getUserDetails())

  return (
    <>
      {isAuthenticated ? (
        <Fragment>{<AuthenticatedRoutes />}</Fragment>
      ) : (
        <Navigate to={LOGIN} />
      )}
    </>
  )
}
