import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import { useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { icons } from '../../assets/img'
import { getUserDetails } from '../../helpers/utils'
import { OVERVIEW } from '../../containers/App/RouteConstants'
import { Alert } from '..'

function AuthLayout({ content, title, pageTitle, subTitle, illustration, action }) {
  const navigate = useNavigate()
  const { notification } = useSelector((state) => state.auth)
  const [alert, setAlert] = useState({
    type: '',
    message: ''
  })

  const isAuthenticated = !isEmpty(getUserDetails())

  useEffect(() => {
    isAuthenticated && navigate(OVERVIEW)
  }, [navigate, isAuthenticated])

  useEffect(() => {
    setAlert({
      type: notification.type,
      message: notification.message
    })
  }, [notification])
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <div className='flex min-h-screen'>
        <div className='flex flex-1 flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-gray-50'>
          <div className='mx-auto w-full max-w-sm lg:w-96'>
            <div>
              <img className='h-12 w-auto m-auto' src={icons.logo} alt='Paylend Logo' />
              <h2 className='mt-6 text-3xl font-bold tracking-tight text-gray-900'>
                {title}
              </h2>
            </div>

            <div className='mt-8'>
              <div>
                <div className='relative mt-6'>
                  <span className='text-sm text-gray-500'>{subTitle}</span>
                </div>
              </div>
              <Alert
                type={alert.type}
                message={alert.message}
                onCancel={() => setAlert({ type: '', message: '' })}
                action={action}
              />
              <div className='mt-6'>{content}</div>
            </div>
          </div>
        </div>
        <div className='relative hidden w-0 flex-1 lg:block'>
          <img
            className='absolute inset-0 h-full w-full object-contain'
            src={illustration}
            alt=''
          />
        </div>
      </div>
    </>
  )
}

AuthLayout.propTypes = {
  content: PropTypes.node.isRequired,
  illustration: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  subTitle: PropTypes.string,
  action: PropTypes.node
}

export default AuthLayout
