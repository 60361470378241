import { PropTypes } from 'prop-types'
import { Provider } from 'react-redux'
import Routes from './Routes'

const App = ({ store }) => {
  return (
    <Provider store={store}>
      <Routes />
    </Provider>
  )
}

App.propTypes = {
  store: PropTypes.object.isRequired
}

export default App
