/* eslint-disable jsx-a11y/anchor-is-valid */
import DashboardLayout from '../DashboardLayout'
import { Helmet } from 'react-helmet'
import {
  BuildingOfficeIcon,
  CheckCircleIcon,
  UsersIcon,
  CheckBadgeIcon,
  ArrowPathIcon
} from '@heroicons/react/20/solid'
import { icons } from '../../assets/img'
import { greetUser } from '../../helpers/index'
import { Button } from '../../components'

const stats = [
  { name: 'Total Users', stat: '6200', icon: UsersIcon },
  { name: 'Total Premises', stat: '886', icon: BuildingOfficeIcon },
  { name: 'Approved Loans', stat: '2690', icon: CheckBadgeIcon },
  { name: 'Pending Loans', stat: '120', icon: ArrowPathIcon }
]

function Home() {
  return (
    <>
      <Helmet>
        <title>Overview</title>
      </Helmet>
      <DashboardLayout>
        <div className=''>
          <div className='bg-white shadow rounded-lg'>
            <div className='px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8'>
              <div className='py-6 md:flex md:items-center md:justify-between'>
                <div className='min-w-0 flex-1'>
                  {/* Profile */}
                  <div className='flex items-center'>
                    <img
                      className='hidden h-16 w-16 rounded-full sm:block'
                      src={icons.iconElsie}
                      alt=''
                    />
                    <div>
                      <div className='flex items-center'>
                        <img
                          className='h-16 w-16 rounded-full sm:hidden'
                          src='https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.6&w=256&h=256&q=80'
                          alt=''
                        />
                        <h1 className='ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9'>
                          {greetUser()}, Paylend Africa
                        </h1>
                      </div>
                      <dl className='mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap'>
                        <dt className='sr-only'>Company</dt>
                        <dd className='flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6'>
                          <BuildingOfficeIcon
                            className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400'
                            aria-hidden='true'
                          />
                          Park Suites, Parklands
                        </dd>
                        <dt className='sr-only'>Account status</dt>
                        <dd className='mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0'>
                          <CheckCircleIcon
                            className='mr-1.5 h-5 w-5 flex-shrink-0 text-green-400'
                            aria-hidden='true'
                          />
                          Verified Partner Account
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className='mt-6 flex space-x-3 md:mt-0 md:ml-4'>
                  <Button label='Top Up Wallet' />
                </div>
              </div>
            </div>
          </div>
          <div className=''>
            <dl className='mt-5 grid grid-cols-1 gap-5 sm:grid-cols-4'>
              {stats.map((item) => (
                <div
                  key={item.name}
                  className='overflow-hidden rounded-lg bg-white shadow'
                >
                  <div className='p-5'>
                    <div className='flex items-center'>
                      <div className='flex-shrink-0'>
                        <item.icon className='h-6 w-6 text-gray-400' aria-hidden='true' />
                      </div>
                      <div className='ml-5 w-0 flex-1'>
                        <dl>
                          <dt className='truncate text-sm font-medium text-gray-500'>
                            {item.name}
                          </dt>
                          <dd>
                            <div className='text-lg font-medium text-gray-900'>
                              {item.stat}
                            </div>
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </DashboardLayout>
    </>
  )
}

export default Home
