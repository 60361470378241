import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { LOGIN } from './RouteConstants'
import { ScrollToTop } from '../../components'
import AuthAndDashboardRoutes from './AuthDashboardRoutes'
import LoginPage from '../Auth/Login'

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path={LOGIN} element={<LoginPage />} />
        <Route element={<AuthAndDashboardRoutes />} path='*' />
      </Routes>
    </BrowserRouter>
  )
}
