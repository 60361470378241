import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { Header, Sidebar } from '../../components'
import { fetchMetrics } from '../Auth/store'

function DashboardLayout({ children }) {
  const dispatch = useDispatch()
  const { user } = useSelector((state) => state.auth)
  const [sidebarOpen, setSidebarOpen] = useState(false)

  useEffect(() => {
    dispatch(fetchMetrics())
  }, [dispatch])

  return (
    <>
      <div className='min-h-full'>
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <div className='flex flex-col md:pl-64'>
          <Header
            setSidebarOpen={setSidebarOpen}
            user={{
              ...user,
              name: user.firstname + ' ' + user.lastname
            }}
          />

          <main className='flex-1'>
            <div className='py-6'>
              {/* <div className='mx-auto max-w-7xl px-4 sm:px-6 md:px-8'>
                <h1 className='text-2xl font-semibold text-gray-900'>Dashboard</h1>
              </div> */}
              <div className='mx-auto max-w-7xl px-4 sm:px-4 md:px-8'>
                {/* Replace with your content */}
                <div className=''>{children}</div>
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}

DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default DashboardLayout
